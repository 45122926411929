// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-index-js": () => import("./../../../src/pages/archive/index.js" /* webpackChunkName: "component---src-pages-archive-index-js" */),
  "component---src-pages-archive-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/archive/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-archive-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-editing-js": () => import("./../../../src/pages/editing.js" /* webpackChunkName: "component---src-pages-editing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-writing-js": () => import("./../../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */)
}

